<template>
  <div>
    <card type="chart">
      <template slot="header">
        <div class="row">
          <div class="col-sm-6">
            <h2 class="card-title">กราฟรวมบอท</h2>
          </div>
          <div
            style="
              top: 0;
              right: 0;
              display: flex;
              position: absolute;
              padding: 20px;
            "
          >
            <div class="row" v-for="(data, i) in botTag" :key="i" :value="data">
              <label class="switch mr-3 ml-4">
                <input
                  type="checkbox"
                  :value="data.value"
                  v-model="data.value"
                  @change="getAllTransection()"
                />
                <span class="slider round"></span>
                <h6 class="mt-1" style="text-align: center; width: 112%">
                  {{ data.title }}
                </h6>
              </label>
            </div>
          </div>
        </div>
      </template>

      <apexchart-model
        :botId="botId"
        :botType="botType"
        :betSide="betSide"
        :setting_data="setting_data"
        :jsonIndex="jsonIndex"
        :chartSize="[100, 350]"
      /> </card
    ><result-bar :botType="botType" :jsonIndex="jsonIndex" />
  </div>
</template>
<script>
import LineChart from "@/components/Charts/LineChart";
import BarChart from "@/components/Charts/BarChart";
import ApexchartModel from "@/views/room/chart/ApexchartModel";

import TaskList from "@/pages/Dashboard/TaskList";

import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import ResultBar from "./ResultBar.vue";

export default {
  components: {
    LineChart,
    BarChart,
    TaskList,
    PulseLoader,
    ApexchartModel,
    ResultBar,
  },
  props: {
    botId: Number,
    botType: Number,
    betSide: { type: Number, default: 1 },
    setting_data: Object,
    jsonIndex: Number,
  },

  mounted() {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  destroyed() {
    this.sockets.unsubscribe("all");
  },
};
</script>
<style>
.font-black {
  font-size: 12px;
  color: black;
}
.li-default {
  color: #2dce89;
}
.li-danger {
  color: #aa1100;
}
.li-blue {
  color: #1d8cf8;
}
.li-primary {
  color: #42b883;
}
.li-info {
  color: #33d2ff;
}
.li-teal {
  color: #00d6b4;
}
.li-white {
  color: #fff;
}
.li-yello {
  color: #eaf30c;
}

li {
  font-size: 20px;
  content: "\2022";
  margin: 0px 20px 10px 20px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 21px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(94, 94, 94);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #5e72e4;
}

input:focus + .slider {
  box-shadow: 0 0 1px #5e72e4;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.winer-tag {
  animation: shake-animation 2s ease infinite;
  transform-origin: 50% 50%;
  font-weight: bold;
  font-size: 16px;
  width: 50px;
  text-align: center;
  color: rgb(255, 255, 255);
  background-color: #468f38;
  border-radius: 4px;
  margin: 0;

  position: absolute;

  margin-right: 20px;
  transform: translate(-50%, -20%);
}

@keyframes shake-animation {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}
</style>
