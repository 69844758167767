<template>
  <div class="dialog-panel dialog-bg">
    <div class="spinner">
      <card type="chart" style="width: 260px; height: 160px">
        <template slot="header">
          <h5 class="card-category">แจ้งเตือน</h5>
          <h3 class="card-title">
            <h4>บอทหยุดทำงาน</h4>
          </h3>
        </template>
        <hr class="style1 mt-0" style="background-color: white; opacity: 0.1" />
        <div class="row">
          <div class="col-lg-6"></div>
          <div class="col-lg-12">
            <button
              type="submit"
              class="btn btn-danger btn-lg w-100 btn-sm"
              @click="destroy_bot"
            >
              กลับหน้าสร้างบอท
            </button>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  components: { PulseLoader },
  data() {
    return {
      loading: true,
      color: "#fff",
      size: "12px",
    };
  },
  methods: {
    destroy_bot() {
      this.$emit("destroy_bot");
    },
  },
};
</script>

<style lang="scss">
.dialog-panel {
  display: flex;
  justify-content: center;
  top: 0px;
  left: 0px;
  position: fixed;

  width: 100%;
  height: 100%;
  z-index: 10001;
}

.dialog-bg {
  min-height: 100vh;
  display: flex;
  background-color: #0000009c;
}
.spinner {
  align-content: center;
  align-items: center;
  align-self: center;
}
</style>