<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template></template>

<script>
export default {
  mounted() {
    this.$store.dispatch("logout").then(() => {
      this.$http
        .post(
          `${process.env.VUE_APP_BACKEND_API_URL}/logout/${this.$store.getters.getUserId}`
        )
        .then(({}) => {
          this.$router.push("/login").catch(() => {});
        })
        .catch((err) => {
          return;
        });
    });
  },
};
</script>

<style lang="scss">
#page-login {
  .social-login-buttons {
    .bg-facebook {
      background-color: #1551b1;
    }
    .bg-twitter {
      background-color: #00aaff;
    }
    .bg-google {
      background-color: #4285f4;
    }
    .bg-github {
      background-color: #333;
    }
  }
}
</style>
