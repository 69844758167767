<template>
  <div>
    <div class="row">
      <div class="col-lg-4">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">โต๊ะน่าเล่น</h5>
            <h3 class="card-title">
              {{
                bot_transaction != null
                  ? `โต๊ะ ${bot_transaction.data.table}`
                  : "กำลังวิเคราะห์"
              }}
            </h3>
          </template>
        </card>
      </div>
      <div class="col-lg-4">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">โอกาสทำกำไร</h5>
            <h3 class="card-title">
              {{
                bot_transaction != null
                  ? botType != 2
                    ? bot_transaction.data.win_percent.toFixed(2) + "%"
                    : bot_transaction.data.win_percent[
                        bet_side_detail(setting.bet_side).code
                      ].toFixed(2) + "%"
                  : "กำลังวิเคราะห์"
              }}
            </h3>
          </template>
        </card>
      </div>
      <div class="col-lg-4">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">บอทวิเคราะห์</h5>
            <h3 class="card-title">
              {{
                bot_transaction != null
                  ? botType != 2
                    ? bot_transaction.data.bot
                    : bot_transaction.data.bot[
                        bet_side_detail(setting.bet_side).code
                      ]
                  : "กำลังวิเคราะห์"
              }}
            </h3>
          </template>
        </card>
      </div>
    </div>
    <div class="row">
      <chart-profit
        :botId="botId"
        :wallet="wallet"
        :setting="setting"
        class="col-lg-12"
      />
      <div class="col-lg-12 col-md-12">
        <card class="card">
          <h4 slot="header" class="card-title">ข้อมูลการเล่น</h4>
          <div class="row" v-if="bot_status">
            <div class="col-lg-6">
              <card type="chart">
                <template slot="header">
                  <h5 class="card-category">
                    {{ !isTrial ? `กระเป๋าเงินหลัก` : `กระเป๋าเงินทดลอง` }}
                  </h5>
                  <h4 class="card-title">
                    {{ wallet_now }}
                  </h4>
                </template>
              </card>
            </div>

            <div class="col-lg-6">
              <card type="chart">
                <template slot="header">
                  <h5 class="card-category">การเล่น</h5>
                  <h4 class="card-title">
                    {{
                      bot_setting.success != null
                        ? `${bot_setting.success.data.current.bet} 
                            ${bot_setting.success.data.betVal}`
                        : ""
                    }}
                    <pulse-loader
                      v-if="bot_setting.success === null"
                      :color="color"
                      :size="size"
                    ></pulse-loader>
                  </h4>
                </template>
              </card>
            </div>
            <div class="col-lg-12" v-if="setting.money_system === 3">
              <card type="chart">
                <template slot="header">
                  <h5 class="card-category">ลาบูแชร์</h5>
                  <h4 class="card-title">
                    {{
                      bot_setting.result != null
                        ? `${bot_setting.result.playData}`
                        : ""
                    }}
                    <pulse-loader
                      v-if="bot_setting.result === null"
                      :color="color"
                      :size="size"
                    ></pulse-loader>
                  </h4>
                </template>
              </card>
            </div>
          </div>

          <div class="d-flex justify-content-between text-center">
            <card type="col-lg-6">
              <h5>รูปแบบการเล่น</h5>
              <h4>
                {{
                  botType != 2
                    ? bet_side_detail(setting.bet_side).title[
                        botType == 1 ? 0 : 1
                      ]
                    : bet_side_detail(setting.bet_side).title
                }}
              </h4>
            </card>
            <card type="col-lg-6">
              <h5>รูปแบบการเดินเงิน</h5>
              <h4>
                {{ money_system_detail(setting.money_system).title }}
              </h4>
            </card>
          </div>
          <div class="d-flex justify-content-between text-center">
            <card type=" col-lg-6">
              <h5>ชิปเริ่มต้น</h5>
              <h4>{{ setting.init_bet }}</h4>
            </card>
            <card type=" col-lg-6">
              <h5>ถอนเมื่อได้กำไร</h5>
              <h4>{{ setting.is_infinite != true ? "ไม่" : "ใช่" }}</h4>
            </card>
          </div>
          <div class="d-flex justify-content-between text-center">
            <card type=" col-lg-6">
              <h5>กำไรเป้าหมาย</h5>
              <h4>
                {{ setting.profit_threshold }}({{ setting.profit_percent }}%)
              </h4>
            </card>
            <card type=" col-lg-6">
              <h5>เสียไม่เกิน</h5>
              <h4>{{ setting.loss_threshold }}({{ setting.loss_percent }}%)</h4>
            </card>
          </div>
          <div
            class="d-flex justify-content-between text-center"
            v-if="botType === 2"
          >
            <card type=" col-lg-12">
              <h5>แทงศูนย์</h5>
              <h4>
                {{ setting.zero_bet }}
              </h4>
            </card>
          </div>
          <div class="d-flex justify-content-between text-center">
            <card type=" col-lg-12">
              <h5>ลิมิตการเล่น</h5>
              <h4>{{ setting.maximum_bet }}</h4>
            </card>
          </div>
        </card>
      </div>
      <div class="col-lg-12">
        <history />
      </div>
    </div>
  </div>
</template>
<script>
import ChartProfit from "../chart/ChartProfit";
import Json_setting from "@/json/bot_setting.json";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import PopupDialog from "@/components/PopupDialog";
import History from "./History.vue";
export default {
  components: { ChartProfit, PulseLoader, PopupDialog, History, ChartProfit },
  mounted() {
    this.getHistory();
    this.wallet_now = this.wallet;
    this.sockets.subscribe(`bot`, (data) => {
      this.getBotTransection(data);
    });
    this.sockets.subscribe(`all`, (data) => {
      if (this.botType != 2) {
        if (data.bot_type === this.botType) {
          this.bot_transaction = null;
          this.bot_setting.success = null;
        }
      } else {
        if (data.bot_type === this.bet_side_detail(this.setting.bet_side).all) {
          this.bot_transaction = null;
          this.bot_setting.success = null;
        }
      }
    });
    this.sockets.subscribe(`user${this.$store.getters.getUserId}`, (data) => {
      if (data.action === "bet_success") {
        this.bot_setting.success = data;
      }
      if (data.action === "bet_result") {
        this.bot_setting.result = data;

        this.deposite_count = data.botObj.deposite_count;
        this.profit_wallet = data.botObj.profit_wallet;
        this.get_wellet();
        this.getHistory();

        if (this.bot_setting.result.isStop) this.is_stop();
      }
    });
    this.check_connection();
    this.sockets.subscribe(`connection_status_${this.botId}`, (data) => {
      this.connect = data.status;
      this.$emit("callback_connect", data.status);
    });

    this.sockets.subscribe(`wallet${this.$store.getters.getUserId}`, (data) => {
      if (data.wallet != null) {
        this.wallet_now = data.wallet;
      }
      this.updateWallet({
        init_wallet: this.wallet_now,
        deposite_count: this.deposite_count,
        profit_wallet: this.profit_wallet,
      });
    });

    setInterval(() => {
      this.check_connection();
    }, 30000);
  },
  props: {
    setting: Object,
    botId: Number,
    bot_status: Boolean,
    wallet: String,
    botType: Number,
  },
  data() {
    return {
      wallet_now: 0,
      deposite_count: 0,
      profit_wallet: 0,
      isStop: false,
      bot_setting: { success: null, result: null },
      bot_transaction: null,
      json_bet_side: Json_setting[this.botType != 2 ? 0 : 1].bet_side,
      json_money_system: Json_setting[this.botType != 2 ? 0 : 1].money_system,
      json_table: Json_setting[this.botType != 2 ? 0 : 1].table,
      //bot_status: false,
      loading: true,
      color: "#fff",
      size: "6px",
      connect: false,
      api_status: false,
    };
  },
  computed: {
    bet_side_detail() {
      return (i) => this.json_bet_side.filter((data) => data.value === i)[0];
    },
    money_system_detail() {
      return (i) =>
        this.json_money_system.filter((data) => data.value === i)[0];
    },
    isTrial() {
      return localStorage.getItem("trial_active") != "false" ? true : false;
    },
  },
  methods: {
    // getPlayInfo(data) {
    //   this.bot_setting = data;
    // },
    updateDestroy() {
      return this.wallet_now;
    },
    updateWallet(data) {
      this.$emit("updateWallet", data);
    },
    getBotTransection(data) {
      if (this.json_table[this.botType - 1].indexOf(data.data.table) != -1) {
        // console.log("botType", data);
        this.bot_transaction = data;
      }
    },
    is_stop() {
      this.$emit("isStop");
    },
    callback_wallet() {
      this.$emit("callback_wallet", {
        init_wallet: this.wallet_now,
        deposite_count: this.deposite_count,
        profit_wallet: this.profit_wallet,
      });
    },
    check_connection() {
      this.$http
        .get(
          `${process.env.VUE_APP_BACKEND_API_URL}/check_connection/${this.$store.getters.getUserId}`
        )
        .then(({ data }) => {
          return data;
        })
        .catch((err) => {
          return;
        });
    },
    getHistory() {
      this.$http
        .get(
          `${process.env.VUE_APP_BACKEND_API_URL}/history/${this.$store.getters.getUserId}`
        )
        .then(({ data }) => {
          console.log(data);
        })
        .catch((err) => {
          return;
        });
    },
    async get_wellet() {
      this.$http
        .get(
          `${process.env.VUE_APP_BACKEND_API_URL}/wallet/${this.$store.getters.getUserId}`
        )
        .then(({ data }) => {
          this.api_status = data.success;
        })
        .catch((err) => {
          this.loading = false;
          this.error = true;
          this.errorMsg = err.response.data.message;
          return;
        });
    },
  },
  beforeDestroy() {},
};
</script>
<style lang="scss">
</style>