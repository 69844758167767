<template>
  <div>
    เงินในกระเป๋าไม่เพียงพอ <b>กรุณากำหนดชิพเริ่มต้นใหม่หรือเติมเงิน</b>
  </div>
</template>

<script>
export default {
  name: "notification-template",
};
</script>
