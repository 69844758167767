<template>
  <div class="load-panel load-bg">
    <div class="spinner">
      <pulse-loader v-if="loading" :color="color" :size="size"></pulse-loader>
    </div>
  </div>
</template>
<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  components: { PulseLoader },
  data() {
    return {
      loading: true,
      color: "#fff",
      size: "12px",
    };
  },
};
</script>

<style lang="scss">
.load-panel {
  display: flex;
  justify-content: center;
  top: 0px;
  left: 0px;
  position: fixed;

  width: 100%;
  height: 100%;
  z-index: 9999;
}

.load-bg {
  min-height: 100vh;
  display: flex;
  background-color: #00000060;
}
.spinner {
  align-content: center;
  align-items: center;
  align-self: center;
}
</style>