<template>
  <div>
    <card type="chart">
      <template slot="header" v-if="!smSize">
        <div class="row">
          <div class="mb-4" style="display: flex">
            <div class="row" v-for="(data, i) in botTag" :key="i" :value="data">
              <label class="switch mr-3 ml-4">
                <input
                  type="checkbox"
                  :value="data.value"
                  v-model="data.value"
                  @change="getAllTransection()"
                />
                <span class="slider round"></span>
                <h6 class="mt-1" style="text-align: center; width: 112%">
                  {{ data.title }}
                </h6>
              </label>
            </div>
          </div>
        </div>
      </template>

      <div id="chart">
        <apexchart
          :width="`${chartSize[0]}%`"
          :height="chartSize[1]"
          ref="realtimeChart"
          type="area"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
    </card>
  </div>
</template>
<script>
import LineChart from "@/components/Charts/LineChart";
import BarChart from "@/components/Charts/BarChart";
import ApexchartModel from "@/views/room/chart/ApexchartModel";

import TaskList from "@/pages/Dashboard/TaskList";

import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import moment from "moment";
import JsonSetting from "@/json/bot_setting.json";
import JsonChartOption from "@/views/room/chart/chart_option.json";

export default {
  components: {
    LineChart,
    BarChart,
    TaskList,
    PulseLoader,
    ApexchartModel,
  },
  props: {
    botId: Number,
    botType: Number,
    betSide: { type: Number, default: 1 },
    setting_data: Object,
    smSize: { type: Boolean, default: false },
    jsonIndex: Number,
    chartSize: Array,
  },

  mounted() {
    if (this.smSize) {
      for (var i = 0; i < this.botTag.length; i++) {
        this.botTag[i].value = true;
      }
    } else {
      for (var i = 0; i < this.botTag.length; i++) {
        if (i === 0) {
          this.botTag[i].value = true;
        } else {
          this.botTag[i].value = false;
        }
      }
    }
    this.updateSeriesLine();
    if (this.botId != null) {
      this.openChart(this.setting_data.bet_side);
    }

    for (var i = 0; i < this.botTag.length; i++) {
      this.series.push({
        name: this.botTag[i].title,
        data: [
          {
            x: "start",
            y: 0,
          },
        ],
      });
    }

    this.getAllTransection();
    this.tag = this.bet_side_detail(this.betSide).title;
    this.sockets.subscribe(`all`, (data) => {
      if (data.bot_type === this.botType) this.getAllTransection();
    });
  },
  data() {
    return {
      json_bet_side: JsonSetting[this.jsonIndex].bet_side,
      lineColor: ["#1890ff", "#1890ff", "#1890ff", "#1890ff", "#1890ff"],
      chart_data: {},
      botTag:
        this.jsonIndex != 0
          ? JsonChartOption[this.jsonIndex].botTag
          : JsonChartOption[this.jsonIndex].botTag[this.botType == 1 ? 0 : 1],

      loading: true,
      color: "#fff",
      size: "6px",

      chartPointer: 0,
      tableId: "",

      chartOptions: {
        dataLabels: {
          enabled: false,
        },
        colors:
          // this.smSize
          //   ? ["#1890ff", "#1890ff", "#1890ff", "#1890ff", "#1890ff"]
          //   :
          JsonChartOption[this.jsonIndex].color,
        fill: {
          opacity: 0.5,
          type: "solid",
          colors: this.smSize
            ? this.lineColor
            : JsonChartOption[this.jsonIndex].fill,
        },
        stroke: {
          curve: "straight",
        },
        xaxis: {
          type: "category",
          labels: {
            show: false,
            align: "right",
            minWidth: 0,
            maxWidth: 160,
            style: {
              colors: ["#fff"],
            },
          },
        },

        yaxis: {
          show: true,
          showAlways: true,

          labels: {
            show: true,
            align: "right",
            minWidth: 0,
            maxWidth: 160,
            style: {
              colors: ["#fff"],
            },
          },
        },
        chart: {
          toolbar: {
            show: this.smSize ? false : true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              customIcons: [],
            },
          },
        },

        grid: {
          show: true,
          borderColor: "#ffffff1a",
          strokeDashArray: 0,
          position: "back",
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          row: {
            colors: undefined,
            opacity: 0.5,
          },
          column: {
            colors: undefined,
            opacity: 0.5,
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        legend: {
          show: true,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: "bottom",
          horizontalAlign: "center",
          floating: false,
          fontSize: "14px",
          fontFamily: "Helvetica, Arial",
          fontWeight: 400,
          formatter: undefined,
          inverseOrder: false,
          width: undefined,
          height: undefined,
          tooltipHoverFormatter: undefined,
          customLegendItems: [],
          offsetX: 0,
          offsetY: 0,
          labels: {
            colors: undefined,
            useSeriesColors: false,
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
          itemMargin: {
            horizontal: 5,
            vertical: 0,
          },
          onItemClick: {
            toggleDataSeries: true,
          },
          onItemHover: {
            highlightDataSeries: true,
          },
        },
        // tooltip: {
        //   custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        //     let PLAYER = "";
        //     let DEFUALT = "";
        //     let BANKER = "";
        //     if (w.config.series[0].data.length != 0) {
        //       DEFUALT =
        //         "<li class='li-default'> <div class='font-black'>" +
        //         w.config.series[0].data[dataPointIndex].x +
        //         "\n" +
        //         w.config.series[0].name +
        //         ": " +
        //         series[0][dataPointIndex] +
        //         "</div></li>";
        //     }

        //     if (w.config.series[1].data.length != 0) {
        //       PLAYER =
        //         "<li class='li-blue'> <div class='font-black'>" +
        //         w.config.series[1].data[dataPointIndex].x +
        //         "\n" +
        //         w.config.series[1].name +
        //         ": " +
        //         series[1][dataPointIndex] +
        //         "</div></li>";
        //     }
        //     if (w.config.series[2].data.length != 0) {
        //       BANKER =
        //         "<li class='li-danger'> <div class='font-black'>" +
        //         w.config.series[2].data[dataPointIndex].x +
        //         "\n" +
        //         w.config.series[2].name +
        //         ": " +
        //         series[2][dataPointIndex] +
        //         "</div></li>";
        //     }

        //     return DEFUALT + PLAYER + BANKER;
        //   },
        // },
      },
      series: [],
    };
  },
  computed: {
    bet_side_detail() {
      return (i) => this.json_bet_side.filter((data) => data.value === i)[0];
    },
  },
  methods: {
    openChart(data) {
      for (var i = 0; i < this.botTag.length; i++) {
        this.series = [];
        this.series.push({
          name: this.botTag[i].title,
          data: {
            x: "start",
            y: 0,
          },
        });
      }

      if (this.jsonIndex != 0) {
        var tag = this.bet_side_detail(data).code;
      } else
        var tag = this.bet_side_detail(data).code[this.botType == 1 ? 0 : 1];

      if (tag != null) {
        for (var i = 0; i < this.botTag.length; i++) {
          this.botTag[i].value = false;
        }
        this.getSomeTransection(tag);

        for (var i = 0; i < this.botTag.length; i++) {
          if (this.botTag[i].title === tag) {
            this.botTag[i].value = true;
          }
        }
      }
    },

    getAllTransection() {
      for (var i = 0; i < this.botTag.length; i++) {
        this.updateSeriesLine();
        this.series[i].data = [];
      }

      for (var i = 0; i < this.botTag.length; i++) {
        if (this.botTag[i].value != false) {
          this.getSomeTransection(this.botTag[i].title);
        } else {
          this.series[i].data = [];
        }
      }
    },
    getSomeTransection(tag) {
      this.$http
        .get(
          `${process.env.VUE_APP_BACKEND_API_URL}/bot_transaction?type=${tag}`
        )
        .then(({ data }) => {
          this.chart_data[tag.toLowerCase()] = data.data.reverse();

          this.getWin(data.data, tag);
          this.updateSeriesLine();
        })
        .catch((err) => {
          return;
        });
    },
    getWin(data, tag) {
      let reverse_data = data;
      this.chartPointer = 0;

      for (var i = 0; i < this.botTag.length; i++) {
        if (tag === this.botTag[i].title) {
          this.series[i].data = [];
          this.series[i].data.push({
            x: "start",
            y: 0,
          });
        }
      }

      this.chartOptions.xaxis.categories = [];
      this.chartOptions.xaxis.categories.push("");
      for (var i = 0; i < reverse_data.length; i++) {
        if (reverse_data[i].win_result === "WIN") {
          this.chartPointer += 1;
        } else if (reverse_data[i].win_result === "LOSE") {
          this.chartPointer -= 1;
        }
        let label =
          "" +
          "ฝั่งที่เล่น " +
          reverse_data[i].bet +
          "<br>" +
          "ผลทำนาย " +
          reverse_data[i].win_result +
          "<br>เวลา " +
          moment(String(reverse_data[i].createdAt)).format("DD/MM/YYYY hh:mm");

        if (tag === this.botTag[0].title) {
          this.series[0].data.push({
            x: label,
            y: this.chartPointer,
          });
        }
        if (tag === this.botTag[1].title) {
          this.series[1].data.push({
            x: label,
            y: this.chartPointer,
          });
        }
        if (tag === this.botTag[2].title) {
          this.series[2].data.push({
            x: label,
            y: this.chartPointer,
          });
        }
        if (this.jsonIndex != 0) {
          if (tag === this.botTag[3].title) {
            this.series[3].data.push({
              x: label,
              y: this.chartPointer,
            });
          }
          if (tag === this.botTag[4].title) {
            this.series[4].data.push({
              x: label,
              y: this.chartPointer,
            });
          }
        }
      }
      this.updateSeriesLine();
    },
    updateSeriesLine() {
      const seriesList = [];
      for (var i = 0; i < this.series.length; i++) {
        seriesList.push({
          data: this.series[i].data,
        });
      }
      this.$refs.realtimeChart.updateSeries(seriesList, false, true);
    },
  },
  destroyed() {
    this.sockets.unsubscribe("all");
  },
};
</script>
<style>
.font-black {
  font-size: 12px;
  color: black;
}
.li-default {
  color: #2dce89;
}
.li-danger {
  color: #aa1100;
}
.li-blue {
  color: #1d8cf8;
}
.li-primary {
  color: #42b883;
}
.li-info {
  color: #33d2ff;
}
.li-teal {
  color: #00d6b4;
}
.li-white {
  color: #000;
}
.li-yello {
  color: #eaf30c;
}

li {
  font-size: 20px;
  content: "\2022";
  margin: 0px 20px 10px 20px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 21px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(94, 94, 94);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #c996cc;
}

input:focus + .slider {
  box-shadow: 0 0 1px #c996cc;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.winer-tag {
  animation: shake-animation 2s ease infinite;
  transform-origin: 50% 50%;
  font-weight: bold;
  font-size: 16px;
  width: 50px;
  text-align: center;
  color: rgba(0, 0, 0, 0.253);
  background-color: #468f38;
  border-radius: 4px;
  margin: 0;

  position: absolute;

  margin-right: 20px;
  transform: translate(-50%, -20%);
}

@keyframes shake-animation {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}
</style>
