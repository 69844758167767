<template>
  <div>
    กรุณากรอกข้อมูล <b>กำหนดชิพเริ่มต้นไม่เกิน 10000 และไม่ต่ำกว่า 10</b>
  </div>
</template>

<script>
export default {
  name: "notification-template",
};
</script>
